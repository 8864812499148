export const environment = {
  production: false,
  errorMessage: " test error message",
  isOffline: false,
  UserValidationLookupUrl:"https://hfdigitaldev.azure-api.net/heart-liability/UserIdentification",
  GetQuestionsLookupUrl:"https://hfdigitaldev.azure-api.net/heart-liability/GetQuestions",
  SubmitQuestionFormLookupUrl:"https://hfdigitaldev.azure-api.net/heart-liability/SubmitTriage",
  maxFileSizeBytes:"10485760",
  maxFileCount:"5",
  allowedFileExtensions:".pdf,.jpeg,.jpg,.png",
  sessionLengthSec:"3600",
  captchaTimeoutMsec:"10000",
  captchaKey:"6LfaipgjAAAAAHUNqOV58GyV76UzoyjtrFsY1-SK",
  ApiSubscriptionKey:"5d53f346416745c6af903f9b28155479",
  ValidatedEnvironment: 0,
  TextBoxCharactersLimit: 250,
  BigTextBoxCharactersLimit: 500
};
